import {Suspense} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import CenteredSpinner from "../components/CenteredSpinner";
import Page from "../components/Page";
import routes from "../routes";
import getSuspendedProtectedRoute from "../routes/getSuspendedProtectedRoute";
import getSuspendedElement from "../utils/getSuspendedElement";
import Root from "../pages/Root";
import {AddObjective} from "../pages/Questionnaires/AddObjective";
import {useAccount, UserRoles} from "../components/AuthProvider/store";

const PrivacyPolicy = getSuspendedElement(
    () => import("../pages/Terms/privacyPolicy"),
);
const TermsOfService = getSuspendedElement(
    () => import("../pages/Terms/terms"),
);
const ClientsPage = getSuspendedProtectedRoute(
    () => import("../pages/Clients"),
);
const ClientPage = getSuspendedProtectedRoute(
    () => import("../pages/Clients/Pages/Client"),
);

const PrintClientPage = getSuspendedProtectedRoute(
    () => import("../pages/PrintClient"),
);

const EditClientPage = getSuspendedProtectedRoute(
    () => import("../pages/Clients/Pages/Edit"),
);

const SessionPage = getSuspendedProtectedRoute(
    () => import("../pages/Session"),
);

const SettingsPage = getSuspendedProtectedRoute(
    () => import("../pages/Settings"),
);

const CalendarPage = getSuspendedProtectedRoute(
    () => import("../pages/CalendarV2"),
);

const QuestionnairesPage = getSuspendedProtectedRoute(
    () => import("../pages/Questionnaires"),
);

const SummaryPage = getSuspendedProtectedRoute(
    () => import("../pages/Homepage/Pages/Director"),
);
const UsersPage = getSuspendedProtectedRoute(
    () => import("../pages/Users"),
);
const QuestionnairePage = getSuspendedProtectedRoute(
    () => import("../pages/Questionnaires/Pages/Questionnaire"),
);
const ClientObjectiveQuestionnaire = getSuspendedProtectedRoute(
    () => import("../pages/Questionnaires/Pages/ClientQuestionnaire"),
);
const ClientSubjectiveQuestionnaire = getSuspendedProtectedRoute(
    () => import("../pages/Questionnaires/Pages/ClientQuestionnaire/Subjective"),
);
const TransactionsPage = getSuspendedProtectedRoute(
    () => import("../pages/Transactions"),
);

const HomePage = getSuspendedProtectedRoute(() => import("../pages/Homepage"));
export const AuthRouter = () => {
    const role = useAccount((s) => s.role);

    const forEveryone = [UserRoles.secretary, UserRoles.therapist, UserRoles.supervisor, UserRoles.administrator, UserRoles.director]

    const routesForRoles = [
        {
            path: routes.privacyPolicy.path,
            element: <PrivacyPolicy/>,
            forRoles: forEveryone
        },
        {
            path: routes.termsOfService.path,
            element: <TermsOfService/>,
            forRoles: forEveryone
        },
        {
            path: routes.clients.path,
            element: <ClientsPage/>,
            forRoles: [UserRoles.director, UserRoles.secretary, UserRoles.therapist]
        },
        {
            path: routes.editClient.path,
            element: <EditClientPage/>,
            forRoles: [UserRoles.director, UserRoles.secretary, UserRoles.therapist]
        },
        {
            path: routes.client.path,
            element: <ClientPage/>,
            forRoles: [UserRoles.director, UserRoles.secretary, UserRoles.therapist]
        },
        {
            path: routes.session.path,
            element: <SessionPage/>,
            forRoles: [UserRoles.director, UserRoles.secretary, UserRoles.therapist]
        },
        {
            path: routes.questionnaires.path,
            element: <QuestionnairesPage/>,
            forRoles: forEveryone
        },
        {
            path: routes.questionnaire.path,
            element: <QuestionnairePage/>,
            forRoles: forEveryone
        },
        {
            path: routes.clientObjectiveQuestionnaire.path,
            element: <ClientObjectiveQuestionnaire/>,
            forRoles: forEveryone
        },
        {
            path: routes.clientSubjectiveQuestionnaire.path,
            element: <ClientSubjectiveQuestionnaire/>,
            forRoles: forEveryone
        },
        {
            path: routes.newObjectiveQuestionnaire.path,
            element: <AddObjective/>,
            forRoles: forEveryone
        },
        {
            path: routes.editObjectiveQuestionnaire.path,
            element: <AddObjective/>,
            forRoles: forEveryone
        },
        {
            path: routes.settings.path,
            element: <SettingsPage/>,
            forRoles: forEveryone
        },
        {
            path: routes.calendar.path,
            element: <CalendarPage/>,
            forRoles: forEveryone
        },
        {
            path: routes.printClient.path,
            element: <PrintClientPage/>,
            forRoles: forEveryone
        },
        {
            path: routes.summary.path,
            element: <SummaryPage/>,
            forRoles: [UserRoles.director]
        },
        {
            path: routes.users.path,
            element: <UsersPage/>,
            forRoles: [UserRoles.director, UserRoles.secretary]
        },
        {
            path: routes.homepage.path,
            element: <HomePage/>,
            forRoles: forEveryone
        },
        {
            path: routes.transactions.path,
            element: <TransactionsPage/>,
            forRoles: [UserRoles.director, UserRoles.secretary]
        }
    ]

    return (
        <BrowserRouter>
            <Page>
                <Suspense fallback={<CenteredSpinner/>}>
                    <Routes>
                        {routesForRoles.map((r) => {
                            if (r.forRoles.includes(role)) {
                                return (
                                    <Route path={r.path} element={r.element}/>
                                )
                            }
                        })}
                        <Route path="/" element={<Root/>}/>
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                </Suspense>
            </Page>
        </BrowserRouter>
    )
};

export default AuthRouter;
